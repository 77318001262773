.methodology {
    margin: 70px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1380px;
}

.methodology .headline_1 {
    text-align: center;
}

.methodology__list {
    width: 90%;
    max-width: 980px;
    list-style: none;
    padding: 0;
    margin: 0;
}

.methodology__list-second {
    list-style: none;
    padding: 0 ;
}

.methodology__list_with_numeration {
    list-style-type: decimal;
    padding: 0 0 0 24px;
}

.methodology__list-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 0 12px 0;
    width: 100%;
}

.methodology__list-second .methodology__list-item {
    margin-bottom: 24px;
}

.methodology__list-ordered {
    list-style: none;
    padding: 0;
}

.methodology__list-ordered li::marker {
    font-size: 24px;
}

@media screen and (max-width: 768px) {
    .methodology__list-ordered li::marker {
        font-size: 16px;
    }
}

.methodology__list-ordered .methodology__list-item {
    display: list-item;
    margin: 0 0 24px 0;
}

.methodology__source-item {
    display: list-item;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 0 24px 0;
    width: 100%;
}

.methodology__button {
    text-decoration: none;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    transition: ease-in-out 200ms;
    width: 75%;
    max-width: 980px;
    padding: 10px 0;
    border: 1px solid white;
    border-radius: 5px;
}

.methodology__button:hover {
    opacity: 0.6;
}

.methodology__list-count {
    font-family: "Fugue Mono KB", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 24px;
    background-color: #0CC744;
    padding: 6px 10px 0;
    margin: 0 6px 0 0 ;
}

.methodology__text {
    font-size: 24px;
    line-height: 36px;
    margin: 19px 0 0;
}

.methodology__list-source {
    font-size: 18px;
    line-height: 150%;
    color: #C4C4C4;
    margin: 18px 0 0 0;
}

@media screen and (max-width: 768px) {
    .methodology__text {
        font-size: 16px;
        line-height: 150%;
    }
    .methodology__list-source {
        font-size: 14px;
        line-height: 150%;
    }

    .methodology__list-count {
        font-size: 24px;
        padding: 6px 8px 0;
    }
}

.methodology__text_bold {
    font-weight: bold;
}

.methodology__subtitle {
    background-color: #0CC744;
    padding: 6px 12px;
    font-weight: normal;
}

.methodology__source {
    display: flex;
    flex-direction: column;
}

.methodology__source-link {
    font-size: 12px;
    line-height: 150%;
    text-decoration-line: underline;
    color: #FFFFFF;
    margin: 0 0 8px 0;
    transition: ease-in-out 200ms;
    cursor: pointer;
}

.methodology__source-link:hover {
    opacity: 0.6;
}

.methodology__source-text {
    font-size: 12px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.5);
}

.methodology__item {
    display: flex;
    flex-direction: column;
    max-width: 980px;
    width: 90%;
    align-items: flex-start;
}

.methodology__text_0 {
    margin: 0;
}

.methodology__text_1 {
    margin: 48px 0 24px;
}

.methodology__list-ordered .methodology__text {
    margin: 8px 0 0 0;
}

@media screen and (max-width: 768px) {
    .methodology__item {
        width: 90%;
    }
}