.why-velo__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    column-gap: 24px;
    row-gap: 72px;
    max-width: 980px;
    margin: 96px 0 0;
}

.why-velo__card {
    display: flex;
    flex-direction: column;
}

.why-velo__card-image {
    margin: 24px 0;
    max-width: 90px;
    max-height: 90px;
    border: 1px solid white;
}

@media screen and (max-width: 1024px) {
    .why-velo__grid {
        grid-template-columns: repeat(2, 1fr);
        max-width: 90%;
        margin: 48px 0;
        row-gap: 48px;
        column-gap: 12px;
    }
}

@media screen and (max-width: 450px){
    .why-velo__card .headline_4 {
        min-height: 50px;
    }
}