.ready {
    margin: 0 0 48px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 980px;
}

.ready__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin: 48px 0 24px;
    max-width: 980px;
}

.ready__card {
    display: flex;
    flex-direction: column;
}

.ready__card-paragraph {
    margin: 24px 0 0 0;
}

.ready__card-title {
    min-height: 110px;
}

.ready__button {
    padding: 12px 0;
    width: 100%;
    align-self: center;
    margin: 24px 0 72px;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    border-radius: 5px;
    background-color: #0CC744;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: ease-in-out 200ms;
}

.ready__button:hover {
    opacity: 0.8;
}

.ready__button-list {
    display: flex;
    padding: 0;
    margin: 24px 10px 24px 0;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-shrink: initial;
}

.ready__button-list-item {
    width: fit-content;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 6px 0 6px 10px;
    flex-grow: 1;
    transition: ease-in-out 200ms;
}

.ready__button-list-item:hover {
    opacity: 0.6;
}

.ready__button-list-text {
    width: 100%;
    margin: 6px 12px;
    font-family: "Fugue Mono KB", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    color: white;
    line-height: 100%;
    text-align: center;
}

.ready__button-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    margin: 24px 0 0 0;
}

.ready__link {
    color: white;
    font-size: 18px;
    line-height: 150%;
    text-decoration: underline;
    align-self: center;
    margin: 0;
    transition: ease-in-out 200ms;
}

.ready__link:hover {
    opacity: 0.6;
}

@media screen and (max-width: 1040px){
    .ready__button {
    }

    .ready__link{
    }
}

@media screen and (max-width: 1020px) {
    .ready {
        margin: 0 0 48px;
        width: 90%;
    }

    .ready__grid {
        margin: 0;
        grid-template-columns: 1fr;
    }

    .ready__card-title {
        min-height: unset;
        margin: 48px 0 0 0;
    }
}

@media screen and (max-width: 768px) {
    .ready__button {
        font-size: 14px;
        line-height: 125%;
        padding: 12px;
        width: 100%;
    }

    .ready__link {
        font-size: 14px;
        line-height: 150%;
    }

    .ready__button-list-item {
        flex-grow: 0;
    }
}