.map-component {
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  margin: 72px 0 100px 0;
  position: relative;
  top: 0;
  left: 0;
  background-clip: content-box;
  background-size: cover;
}

@supports not (-webkit-touch-callout: none) {
  /* CSS for other than iOS devices */
  .map-component {
    background-attachment: fixed;
  }
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .map-component {
  }
}

.map-component__container {
  width: 75%;
  max-width: 980px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 90vh;
  margin: 24px 0;
}

.map-component__header {
  display: flex;
  flex-direction: column;
}

.map-component__headline {
  text-align: start;
  width: fit-content;
}

.map-component__headline span {
  background-color: #121212;
  box-shadow: 10px 0 0px 0px #121212, -10px 0 0px 0px #121212;
  padding: 6px 0;
}

.map-component__select {
  font-family: "Fugue Mono KB", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: white;
  background: none;
  width: 100%;
  max-width: 250px;
  margin: 0;
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  border-radius: 5px;
  border: 1px solid #ffffff;
  cursor: pointer;
  transition: ease-in-out 200ms;
  appearance: none;
}

.map-component__select-wrapper {
  position: relative;
  max-width: 250px;
  background: #121212;
  z-index: 1;
  margin: 16px 0 0 0;
}

.map-component__select-wrapper::after {
  content: "Список";
  color: rgba(0, 0, 0, 0);
  font-size: 1rem;
  top: 40%;
  right: 10px;
  position: absolute;
  background-size: contain;
  width: 12px;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf-8,<svg width="70" height="40" viewBox="0 0 70 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <line x1="4.24264" y1="5" x2="35" y2="35.7574" stroke="white" stroke-width="6" stroke-linecap="round"/> <line x1="3" y1="-3" x2="46.4975" y2="-3" transform="matrix(-0.707107 0.707107 0.707107 0.707107 70 5)" stroke="white" stroke-width="6" stroke-linecap="round"/> </svg> ');
  z-index: -1;
  transition: ease-in-out 200ms;
}

.map-component__select-wrapper:hover::after {
  opacity: 0.6;
}

.map-component__select:hover {
  opacity: 0.6;
}

.map-component__select option {
  text-transform: capitalize;
  color: black;
}

.map-component__legend {
  align-self: flex-end;
  justify-self: flex-end;
  max-width: 560px;
}

.map-component__spinner {
  margin: auto auto;
  max-width: 24px;
  max-height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  animation: Spinner 1s linear infinite;
}

.map-component__tip {
  font-size: 12px;
  line-height: 125%;
  color: #c9c9c9;
  margin: 8px 0;
  padding: 6px 0;
  /*background: #121212;*/
  width: fit-content;
}

@keyframes Spinner {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.map-component__logos {
  background-color: #121212;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 24%;
  padding: 8px 12px;
  height: fit-content;
}

.map-component__logo {
  max-height: 24px;
  max-width: 100px;
  transition: ease-in-out 200ms;
}

.map-component__logo-cross {
  margin: 0 12px;
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  text-transform: uppercase;
  font-family: "Fugue Mono KB", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.map-component__logos-and-legend {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

@media screen and (max-width: 1024px) {
  .map-component {
    margin: 48px 0 48px 0;
  }

  .map-component__logos-and-legend {
    flex-direction: column-reverse;
    width: 100%;
    align-items: flex-start;
  }

  .map-component__logos {
    width: fit-content;
    padding: 2px 4px;
  }
}

@media screen and (max-width: 768px) {
  .map-component {
    min-height: 85vh;
  }

  .map-component__container {
    width: 90%;
    min-height: 85vh;
  }

  .map-component__legend {
    width: 100%;
    margin-bottom: 12px;
  }

  .map-component__select {
    max-width: 450px;
    line-height: 115%;
    padding: 6px;
  }

  .map-component__select-wrapper {
    max-width: 450px;
  }

  .map-component__logo {
    max-width: 50px;
  }

  .map-component__logo-strelka {
    max-width: 80px;
  }

  }

@media screen and (max-width: 450px) {
  .map-component__select-wrapper {
    max-width: 260px;
  }

}