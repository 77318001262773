@font-face {
    font-family: 'Atlas Grotesk';
    font-style:  normal;
    font-weight: 900;
    font-display: swap;
    src: url("./Atlas_Grotesk-Bold-Web.woff") format('woff');
}

@font-face {
    font-family: 'Atlas Grotesk';
    font-style:  normal;
    font-weight: 500;
    font-display: swap;
    src: url("./Atlas_Grotesk-Regular-Web.woff") format('woff');
}