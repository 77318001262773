.share {

}

.share__button {
    max-width: 24px;
    max-height: 24px;
    width: 100%;
    height: 100%;
    transition: ease-in-out 200ms;
    cursor: pointer;
}

.share__button:hover {
    opacity: 0.6;
}

.share__button:active {
    opacity: 0.5;
}

.share__button:disabled {
    opacity: 0.2;
}