.footer {
    width: 100%;
    background-color: #121212;
    border-top: 1px solid white;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    overflow-y: auto;
}

.footer__logos {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.footer__logo {
    max-height: 56px;
    max-width: 180px;
    transition: ease-in-out 200ms;
}

.footer__logo:hover {
    opacity: 0.6;
}

.footer__container {
    width: 100%;
    display: flex;
    margin: 0;
    justify-content: space-between;
    max-width: 1300px;
    height: 100%;
}

.footer__title-container {
    display: flex;
    flex-direction: column;
    margin: 48px 0 0 0;
    width: 60%;
}

.footer__title {
    font-family: "Fugue Mono KB", Helvetica, Arial, sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    margin: 0;
    /*cursor: pointer;*/
    font-size: 74px;
    line-height: 89px;
    display: flex;
    align-items: center;
}

.footer__subtitle {
    font-family: "Fugue Mono KB", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-transform: uppercase;
    margin: 0;
    max-width: 440px;
}

.footer__link {
    font-family: "Atlas Grotesk", Helvetica, Arial, sans-serif;
    transition: ease-in-out 200ms;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0 0 24px 0;
    text-decoration-line: underline;
}

.footer__link:hover {
    opacity: 0.6;
}

.footer__link:last-child {
    margin: 0;
}

.footer__links {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer__links-container {
    display: flex;
    flex-direction: column;
}

.footer__links-title {
    font-family: "Atlas Grotesk", Helvetica, Arial, sans-serif;
    font-size: 27px;
    font-weight: 900;
    line-height: 32px;
    margin: 70px 0 0 0;
}

.footer__nav {
    display: grid;
    grid-template-columns: 1fr;
    gap: 80px;
    margin: 48px 0 0 0;
}

.footer__made-by {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 48px 0;
    align-items: baseline;
    max-width: 1300px;
}

.footer__made-by-text {
    font-size: 20px;
    line-height: 150%;
    color: #EEEEEE;
    margin: 48px 0 0 0;
}

.footer__made-by-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 14px;
    align-items: baseline;
}

.footer__contacts {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer__contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer__email-icon {
    width: 30px;
    height: 30px;
}

.footer__contact-text {
    margin: 0;
    font-size: 20px;
    line-height: 150%;
}

.footer__contacts-title {
    margin: 0 0 20px 0;
    font-size: 20px;
    line-height: 150%;
    font-weight: bold;
}

.footer__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    align-items: flex-start;
}

.footer__contact-link {
    color: white;
    transition: ease-in-out 200ms;
}

.footer__contact-link:hover {
    opacity: 0.6;
}

@media screen and (max-width: 1280px) {
    .footer__container {
        display: flex;
        flex-direction: column;
        width: 95%;
    }

    .footer__made-by {
        width: 95%;
    }
}

@media screen and (max-width: 768px) {
    .footer__title {
        font-size: 36px;
        line-height: 43px;
        margin-bottom: 24px;
    }

    .footer__title-container {
        width: 100%;
    }

    .footer__subtitle {
        font-size: 18px;
        line-height: 25px;
    }

    .footer__container {
        display: flex;
        flex-direction: column;
        width: 90%;
    }

    .footer__nav {
        grid-template-columns: 1fr;
        gap: 0;
        margin-top: 24px;
    }

    .footer__made-by {
        flex-direction: column;
    }

    .footer__contacts {
        margin: 0;
    }

    .footer__contacts-title {
        font-size: 16px;
        line-height: 24px;
        margin: 0;
    }

    .footer__contact-text {
        font-size: 16px;
        margin: 0;
    }

    .footer__info {
        align-items: flex-start;
        border-top: 1px solid rgba(255, 255, 255, 0.5)
    }

    .footer__made-by {
        width: 90%;
    }

    .footer__made-by-container {
        grid-template-columns: 1fr;
    }

    .footer__made-by-text {
        font-size: 16px;
        margin: 48px 0 0 0;
    }

    .footer__link {
        font-size: 16px;
        line-height: 19px;
        margin: 0 0 12px 0;
    }

    .footer__title {
        font-size: 36px;
        line-height: 115%;
        margin: 0;
    }
}
