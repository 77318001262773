.headline_1 {
    font-family: "Fugue Mono KB", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 82px;
    line-height: 98px;
    text-transform: uppercase;
    margin: 0;
    text-align: start;
    width: 100%;
    max-width: 980px;
}

.headline_2 {
    font-family: "Fugue Mono KB", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 68px;
    line-height: 82px;
    text-transform: uppercase;
    margin: 120px 0 72px;
    text-align: start;
    width: 100%;
    max-width: 980px;
}

.headline_3 {
    font-family: "Fugue Mono KB", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 125%;
    text-transform: uppercase;
    margin: 0;
    text-align: start;
    width: 100%;
    max-width: 980px;
}

.headline_4 {
    font-family: "Fugue Mono KB", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-transform: uppercase;
    margin: 0;
    text-align: start;
    max-width: 980px;
}

.subtitle {
    font-style: normal;
    font-family: "Atlas Grotesk", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 12px;
    line-height: 125%;
    color: #000000;
    margin: 0;
}

.paragraph_1 {
    font-family: "Atlas Grotesk", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    margin: 0;
    max-width: 980px;
}

.paragraph_2 {
    font-family: "Atlas Grotesk", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    max-width: 980px;
    margin: 0;
}

.paragraph__accent {
    background-color: #0CC744;
    margin-right: 12px;
    position: relative;
    z-index: 1;
    padding: 8px 16px 2px;
    text-align: center;
    line-height: 100%;
}

.paragraph_2 .paragraph__accent {
    padding: 3px 8px 6px;
    min-width: 12px;
    min-height: 12px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width: 768px){
    .paragraph__accent {
        background-color: #0CC744;
        margin-right: 2px;
        position: relative;
        z-index: 1;
        padding: 6px 8px 2px;
        text-align: center;
        line-height: 100%;
    }

    .paragraph_2 .paragraph__accent {
        padding: 3px 6px 6px;
        min-width: 12px;
        min-height: 12px;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
    }
}

.legend {
    font-family: "Atlas Grotesk", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 125%;
    margin: 0;
}

.footnote {
    font-weight: 700;
    font-size: 1em;
    text-decoration: none;
    color: white;
    cursor: pointer;
    transition: ease-in-out 200ms;
}

.footnote:hover {
    color: #0CC744;
}

@media screen and (max-width: 1024px) {
    .headline_1 {
        max-width: 90%;
    }

    .headline_2 {
        max-width: 90%;
    }

    .headline_3 {
        max-width: 90%;
    }

    .headline_4 {
        max-width: 90%;
    }

    .paragraph_1 {
        max-width: 90%;
    }

    .paragraph_2 {
        max-width: 90%;
    }
}

@media screen and (max-width: 768px) {
    .headline_1 {
        font-family: "Fugue Mono KB", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 58px;
        text-transform: uppercase;
        margin: 0;
    }

    .headline_2 {
        font-family: "Fugue Mono KB", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 43px;
        text-transform: uppercase;
        margin: 72px 0 36px;
        word-break: break-word;
    }

    .headline_3 {
        font-family: "Fugue Mono KB", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 125%;
        text-transform: uppercase;
        margin: 0;
    }

    .headline_4 {
        font-family: "Fugue Mono KB", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        text-transform: uppercase;
        margin: 0;
    }

    .subtitle {
        font-style: normal;
        font-family: "Atlas Grotesk", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: bold;
        font-size: 12px;
        line-height: 125%;
        color: #000000;
        margin: 0;
    }

    .paragraph_1 {
        font-family: "Atlas Grotesk", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        margin: 0;
    }

    .paragraph_2 {
        font-family: "Atlas Grotesk", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        margin: 0;
    }

    .legend {
        font-family: "Atlas Grotesk", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 125%;
        margin: 0;
    }
}

.paragraph__dot {
    max-width: 11px;
    max-height: 11px;
    margin-right: 6px;
}