.navbar {
    position: fixed;
    height: 100vh;
    width: 100%;
    transition: ease-in-out 200ms;
    background-color: #121212;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
}

.navbar .footer {
    margin: 24px 0 0 0;
    width: 100%;
    border: none;
    display: flex;
    justify-content: space-between;
    overflow: scroll;
    max-width: 980px;
}


.navbar_active {
    visibility: visible;
    opacity: 1;
}

.navbar__button {
    z-index: 1;
    cursor: pointer;
    transition: ease-in-out 200ms;
    max-width: 24px;
    width: 100%;
}

@media screen and (max-width: 425px){
    .navbar__button {
        max-width: 24px;
    }
}

.navbar__button:hover {
    opacity: 0.6;
}

.navbar__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    width: 90%;
    height: 90%;
    grid-template-areas: "logos . ." "list list contacts" "list list contacts";
}

.navbar__list {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    grid-area: list;
    list-style: none;
}

.navbar__list-item-link {
    text-decoration: none;
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    transition: ease-in-out 200ms;
    margin: 0 0 12px 0;
}

.navbar__list-item-link:hover {
    opacity: 0.6;
    cursor: pointer;
}

.navbar__icon {
    width: 30px;
    height: 30px;
    fill: #ffffff;
    position: absolute;
    top: 40px;
    right: 5%;
    transition: ease-in-out 200ms;
    cursor: pointer;
}

.navbar__icon:hover {
    opacity: 0.6;
}

.nav__logos {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 30%;
    grid-area: logos;
    margin: 0 0 20% 0;
}

.nav__logo {
    max-height: 38px;
    max-width: 120px;
    transition: ease-in-out 200ms;
}

.nav__logo:hover {
    opacity: 0.6;
}

.nav__logo-cross {
    width: 100%;
    height: 100%;
    max-width: 9px;
    max-height: 9px;
    margin: 0 7px;
}

.nav__contacts {
    grid-area: contacts;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
}

.nav__contact {
    margin: 0 0 24px ;
}

.nav__contact-text {
    margin: 0;
    font-size: 16px;
    line-height: 150%;
}

.nav__contact-text_underlined {
    text-decoration: underline;
    color: white;
    cursor: pointer;
    transition: ease-in-out 200ms;
    margin: 3px 0 0 0;
}

.nav__contact-text_underlined:hover {
    opacity: 0.6;
}

.nav__rules {
    display: flex;
    flex-direction: column;
    margin-top: 30%;
}

.navbar__share-and-button {
    display: flex;
    width: 25%;
    justify-content: space-between;
}

.navbar__share {
    display: grid;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    grid-template-columns: repeat(4, 30px);
    justify-items: center;
    gap: 6px;
}

.menu__share {
    display: none;
}

@media screen and (max-width: 1024px){

}

@media screen and (max-width: 768px) {
    .nav__logos {
        margin: 0 0 24px 0;
    }

    .navbar__container {
        grid-template-areas: "logos" "list" "contacts";
        overflow: scroll;
    }

    .navbar__list-item {
        font-size: 16px;
        line-height: 19px;
        margin: 12px 0;
    }

    .navbar__share-and-button {
        justify-content: flex-end;
    }

    .navbar__share-divider {
        display: none;
    }

    .navbar__share {
        display: none;
    }
    .menu__share {
        display: flex;
        width: 100%;
        max-width: 170px;
        justify-content: space-between;
    }

    .navbar {
        justify-content: flex-start;
    }

    .navbar .footer {
        justify-content: flex-start;
    }
}