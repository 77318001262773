.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    margin: 120px auto 0;
    position: relative;
    width: 100%;
}

.title__image {
    width: 100%;
}

.title__container {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 0 72px;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
}

.title__container .headline_1 {
    margin-bottom: 24px;
}

.title__logos {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.title__logo {
    max-height: 72px;
    max-width: 200px;
    transition: ease-in-out 200ms;
}

.title__logo_strelka {
    max-width: 220px;
}

.title__logo:hover {
    opacity: 0.6;
}

.title__logo-cross {
    width: 100%;
    max-width: 18px;
    margin: 0 24px;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    text-transform: uppercase;
    font-family: "Fugue Mono KB", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.title__image {
    margin-top: -36px;
}

.title__texts {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 85%;
}

.title__text {
    font-family: "Fugue Mono KB", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 9px;
    line-height: 150%;
    text-transform: uppercase;
    margin: 0;
}

.title__text_headline {
    font-size: 24px;
    line-height: 125%;
    margin: 0 12px 0 0;
}

@media screen and (max-width: 1024px) {
    .title {
        margin-top: 72px;
    }

    .title__container {
        margin-bottom: 48px;
    }

    .title__container .headline_1 {
        width: 90%;
        margin-bottom: 12px;
    }

    .title__logos {
    }

    .title__container .headline_3 {
        width: 90%;
    }
}

@media screen and (max-width: 768px) {
    .title__logo {
        max-width: 100px;
    }

    .title__logo_strelka {
        max-width: 120px;
    }

    .title__image {
        margin-top: 0;
    }

    .title__logo-cross {
        font-size: 15px;
        margin: 0 12px;
        width: unset;
    }
}

@media screen and (max-width: 550px) {
    .title__text_headline {
        max-width: 35%;
    }
}

@media screen and (max-width: 425px){
    .title__logo-cross {
        margin: 0 6px;
    }

    .title__logo {
        max-width: 72px;
    }

    .title__container {
        margin-bottom: 24px;
    }

    .title__text_headline {
        max-width: 45%;
    }
}