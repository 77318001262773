.table-component {
    max-width: 980px;
    margin: 0 0 24px 0;
}

.table__mobile-info {
    display: none;
}

.table__container .headline_4 {
    margin: 24px 0;
}

.table__container .paragraph_2 {
    max-width: 100%;
}


.table-component:last-child {
    margin: 0;
}

.table-component_high {
    border: 2px solid #0CC744;
}

.table-component_average {
    border: 2px solid #F3AF49;
}

.table-component_low {
    border: 2px solid #EB4A6A;
}

.table-component_high .headline_4 {
    color: #0CC744;
}

.table-component_average .headline_4 {
    color: #F3AF49;
}

.table-component_low .headline_4 {
    color: #EB4A6A;
}

.table__container {
    margin: 12px 24px;
}

.table {
    display: flex;
    flex-direction: column;

}

.table__item {
    display: flex;
    flex-direction: column;
}

.table__desc {
    height: 100%;
    max-height: 0;
    visibility: hidden;
    opacity: 0;
    transition: ease-in-out 200ms;
    display: flex;
}

.table__desc .paragraph_2 {
    margin: 12px;
}

.table__desc_opened {
    max-height: 250px;
    opacity: 1;
    visibility: visible;

}

.table__cell {
    padding: 0 9px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    border-bottom: 1px solid #7B7B7B;
    min-height: 24px;
    transition: ease-in-out 200ms;
    font-size: 18px;
    text-align: end;
}

.table__cell_bold {
    font-weight: bold;
}

.table__cell_last {
    border: none;
}

.table__row_head .table__cell {
    border-bottom: 1.5px solid #AEAEAE;
    font-size: 12px;
    padding: 12px 6px 12px 12px;
    max-height: 45px;
}

.table__cell_city {
    align-items: flex-start;
    padding: 12px 2px;
    text-align: left;
}

.table__row {
    margin: 0;
    transition: ease-in-out 200ms;
    display: grid;
    grid-template-columns: 2fr repeat(4, 1fr) 1fr;
}

.table__row:hover {
    cursor: pointer;
}

.table__row_selected {
    color: white;
}

.table__row_head {
    color: white;
}

.table__row_head:hover {
    opacity: 1;
    cursor: auto;
}

.table__row_head .table__cell {
    /*font-variant: small-caps;
    text-transform: lowercase;*/
    text-transform: uppercase;
    text-align: right;
    font-variant: all-small-caps;
}

.table__row:last-child .table__cell {
    border-bottom: none;
}


.table-component_high .table__row_selected .table__cell {
    background-color: #0DC344;
}

.table-component_average .table__row_selected .table__cell {
    background-color: #F3AF49;
}

.table-component_low .table__row_selected .table__cell {
    background-color: #EB4A6A;
}


.table-component_high .table__row:hover .table__cell {
    background-color: rgba(13, 195, 68, 1);
}

.table-component_high .table__row_selected:hover .table__cell {
    background-color: rgba(13, 195, 68, 1);
}

.table-component_average .table__row:hover .table__cell {
    background-color: rgba(243, 175, 73, 1);
}

.table-component_average .table__row_selected:hover .table__cell {
    background-color: rgba(243, 175, 73, 1);
}

.table-component_low .table__row:hover .table__cell {
    background-color: rgba(235, 74, 106, 1);
}

.table-component_low .table__row_selected:hover .table__cell {
    background-color: rgba(235, 74, 106, 1);
}

.table-component_high .table__row_head:hover .table__cell {
    background: none;
}

.table-component_average .table__row_head:hover .table__cell {
    background: none;
}

.table-component_low .table__row_head:hover .table__cell {
    background: none;
}

.table__icon {
    cursor: pointer;
    transition: ease-in-out 200ms;
    max-width: 12px;
    max-height: 12px;
    align-self: flex-end;
}

.table__icon:hover {
    opacity: 0.7;
}

.table__icon_opened {
    transform: rotate(-180deg);
}

@media screen and (max-width: 768px) {
    .table-component {
        width: 97%;
    }

    .table__mobile-info {
        display: grid;
        grid-template-areas: "info1 info2 info2" "info3 info4 info5";
        gap: 24px;
        margin: 32px 0;
    }
    .table__info-text {
        font-size: 9px;
        line-height: 125%;
        letter-spacing: -0.035em;
        text-transform: uppercase;
        font-variant: all-small-caps;
    }

    .table__info-item {
        display: flex;
        align-items: flex-start;
    }

    .table__info-text {
        margin: 0;
    }

    .table__info-num {
        margin: 0 6px 0 0;
        font-weight: bold;
        font-size: 10px;
        line-height: 125%;
        color: #0CC744;
    }

    .table__cell {
        font-size: 12px;
        line-height: 125%;
    }

    .table__cell_city {
        padding: 12px 0;
    }
    .table__row_head .table__cell_city {
        padding: 12px 0;
    }

    .table__container {
        margin: 12px;
    }
}