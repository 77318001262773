.conclusions {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.conclusions__paragraph {
    margin: 48px 0 0 0;
}

@media screen and (max-width: 768px) {
    .conclusions__paragraph {
        margin: 24px 0 0 0;
    }

    .conclusions .paragraph__accent {
        margin-right: 6px;
    }
}