.main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.main__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: 100%;
}

@media screen and (max-width: 1024px) {

}