.page {
    min-height: 100vh;
    font-family: "Atlas Grotesk", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    margin: 0 auto;
    background-color: #121212;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.block-scroll {
    height: 100%;
    overflow-y: hidden;
}

body {
    background-color: #121212;
}