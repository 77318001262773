.step {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 0 96px 0;
    align-items: flex-start;
    max-width: 980px;
}

.step:last-of-type {
    margin-bottom: 48px;
}

.step_reverse {
    display: flex;
    flex-direction: row-reverse;
}

.step__container {
    display: flex;
    flex-direction: column;
}

.step__button-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 980px;
}

.step__number {
    background: #0CC744;
    padding: 0.5% 12px;
    margin: 0;
    width: fit-content;
}

.step__title {
    font-family: "Fugue Mono KB", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 48px;
    line-height: 125%;
    text-transform: uppercase;
    margin: 24px 0 50px;
}

.step__text {
    margin: 0;
    font-size: 24px;
    line-height: 36px;
}

.step__image {
    object-fit: contain;
    margin: 0 0 0 30px;
    width: 50%;
    max-width: 587px;
    mix-blend-mode: lighten;
}

.step__image_mobile {
    display: none;
}

.step__image_reverse {
    /*transform: scaleX(-1);*/
    margin: 0 30px 0 0;
}

.step__list {
    list-style: upper-latin;
    padding: 0 0 0 24px;
}

.step__container .headline_4 .paragraph__accent {
    padding: 8px 10px 2px;
}

.advices-paragraph {
    width: 100%;
}

@media screen and (max-width: 1024px) {
    .step {
        width: 90%
    }

    .step__container .headline_4 .paragraph__accent {
        padding: 6px 8px 2px;
        margin-right: 4px;
    }

    .step__button-container {
        width: 95%;
    }

    .advices-paragraph {
        width: 90%;
    }
}

@media screen and (max-width: 768px) {
    .step {
        width: 90%;
        flex-direction: column-reverse;
        margin: 0 0 48px 0;
    }
    .step__image {
        display: none;
    }

    .step__button-container {
        width: 90%;
    }

    .step__advice-image {
        display: none;
    }

    .step__image_mobile {
        display: flex;
        margin: 0 0 24px 0;
        max-width: unset;
        width: 100%;
    }
}
