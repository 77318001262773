.menu {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
    min-height: 24px;
    width: 100%;
    opacity: 1;
    visibility: visible;
    height: min-content;
    transition: ease-in-out 200ms;
    background: #121212;
    z-index: 10;
}

.menu__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    min-height: 48px;
    max-width: 980px;
    margin: 12px 0;
    transition: ease-in-out 200ms;
}

.menu_hidden {
    visibility: hidden;
    opacity: 0;
}

@media screen and (max-width: 1240px) {
    .menu__container {
        width: 90%;
    }
}

.menu-item_hidden {
    display: none;
}