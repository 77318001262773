.crossroads__container {
    overflow-x: auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.crossroads {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    max-width: 980px;
    margin: 48px 0 0;
}

.crossroads-item {
    display: flex;
    flex-direction: column;
    max-width: 160px;
    width: 100%;
    height: 100%;
    margin-right: 6px;
}
.crossroads-image {
    /*max-width: 120px;*/
    width: 130px;
    height: 130px;
    margin-right: 18px;
}

.crossroads-info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 12px 0 0 0;
}

.crossroads-text {
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
    margin: 0;
}

.crossroads-text_accent {
    font-family: "Fugue Mono KB", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    margin: 0 6px 0 0;
}

@media screen and (max-width: 1320px) {
    .crossroads {
        width: 95%;
        padding: 0 24px;
        margin: 24px 0 0 0;
    }

    .crossroads::after {
        content: "";
        min-width: 1px;
    }
}
