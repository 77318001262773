.choice__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 72px 0 48px;
    max-width: 1300px;
}

/*
.choice__container:first-of-type {
    margin-bottom: 0;
}
*/

.choice__image {
    max-width: 310px;
    width: 100%;
    height: 100%;
    max-height: 75%;
}

.choice__title {
    font-size: 24px;
    line-height: 36px;
    width: 100%;
    margin: 0 auto 24px;
    font-family: "Fugue Mono KB", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    max-width: 1440px;
}

.choice__items-container {
    will-change: transform;
    width: 100%;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 1440px;
}

::-webkit-scrollbar {
    display: none;
}

.choice__items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.choice__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.choice__svg-container {
    position: relative;
}

.choice__svg {
    position: absolute;
    top: 0;
    bottom: 50%;
    left: 50px;
    width: 100%;
    height: 100%;
}

.choice__text {
    font-size: 12px;
    margin: 12px 0 0 0;
}

@media screen and (max-width: 1320px){
    .choice__items {
        width: 100%;
    }

    .choice__items-container {
        width: 95%;
    }

    .choice__title {
        width: 95%;
    }
}

@media screen and (max-width: 768px) {

    .choice__title {
        font-size: 18px;
        line-height: 25px
    }

    .choice__items {
        width: 150%;
        align-items: flex-start;
        padding: 0 12px;
    }

    .choice__items-container {
        width: 100%;
        height: 100%;
    }

    .choice__image {
        object-fit: cover;
    }

    .choice__item {
        width: unset;
    }
}

@media screen and (max-width: 425px) {
    .choice__items {
        width: 300%;
    }

    .choice__container {
        margin: 24px 0;
    }
}